import {
  getCategoriesApi,
  getAttributesApi,
  getChainsApi,
  getAccountsApi,
  getInvitationsApi,
  getAccountNotificationsApi,
  postAccountNotificationsApi,
  postInvitationApi,
  postAssociateGmbAccount,
} from '@/services/gmb.service'

import { tableOptions } from '@/utils/table.util'
import AVAILABLES_COUNTRIES from '@/lovs/gmbAvailableCountries'
import NOT_AVAILABLES_COUNTRIES from '@/lovs/gmbNotAvailableCountries'
import LANGUAGE_CODES from '@/lovs/gmbLanguageCodes'

const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_CHAINS = 'SET_CHAINS'
const SET_INVITATIONS = 'SET_INVITATIONS'
const SET_ACCOUNTS = 'SET_ACCOUNTS'
const SET_ACCOUNTS_PAGINATION = 'SET_ACCOUNTS_PAGINATION'
const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
const SET_ADMINS = 'SET_ADMINS'
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

const initialState = () => {
  return {
    availablesCountries: AVAILABLES_COUNTRIES,
    notAvailablesCountries: NOT_AVAILABLES_COUNTRIES,
    languageCodes: LANGUAGE_CODES,
    categories: {},
    attributes: [],
    chains: [],
    invitations: {},
    accounts: [],
    accountsPagination: {},
    admins: [],
    notifications: {},
  }
}

const mutations = {
  [SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  },
  [SET_CHAINS]: (state, chains) => {
    state.chains = chains
  },
  [SET_INVITATIONS]: (state, invitations) => {
    state.invitations = invitations
  },
  [SET_ACCOUNTS]: (state, accounts) => {
    state.accounts = accounts
  },
  [SET_ACCOUNTS_PAGINATION]: (state, pagination) => {
    state.accountsPagination = pagination
  },
  [SET_ATTRIBUTES]: (state, attributes) => {
    state.attributes = attributes
  },
  [SET_ADMINS]: (state, admins) => {
    state.admins = admins
  },
  [SET_NOTIFICATIONS]: (state, notifications) => {
    state.notifications = notifications
  },
}

const actions = {
  async loadCategories({ commit }, { regionCode, view, locale, size, filter = '', nextPageToken }) {
    const params = {
      regionCode,
      locale,
      view,
      nextPageToken,
      size,
      filter: tableOptions({ filter }, 'gmbCategories'),
    }
    const { data, next_page_token } = await getCategoriesApi(params)
    commit(SET_CATEGORIES, {
      data: data.results,
      next: next_page_token
        ? {
            ...params,
            nextPageToken: next_page_token,
          }
        : null,
    })
  },
  async loadMoreCategories({ commit, state }) {
    const { data, next_page_token: nextPageToken } = await getCategoriesApi(state.categories.next)
    commit(SET_CATEGORIES, {
      data: [...state.categories.data, ...data.results],
      next: { ...state.categories.next, nextPageToken },
    })
  },
  async getChains({ commit }, query) {
    const { data } = await getChainsApi(query)
    commit(SET_CHAINS, data.results)
  },
  async loadInvitations({ commit }) {
    const { data } = await getInvitationsApi()
    commit(SET_INVITATIONS, data)
  },
  async getAccounts({ commit }, tableParams) {
    const urlParams = tableOptions(tableParams)
    const { data } = await getAccountsApi(urlParams)
    commit(SET_ACCOUNTS, data.results)
    delete data.results
    commit(SET_ACCOUNTS_PAGINATION, data)
  },

  async getAttributes({ commit }, { countryCode, languageCode, categoryId }) {
    const { data } = await getAttributesApi(countryCode, languageCode, categoryId)
    commit(SET_ATTRIBUTES, data.results)
  },

  clearAttributes({ commit }) {
    commit(SET_ATTRIBUTES, [])
  },
  clearStore({ commit }) {
    commit(SET_CATEGORIES, {})
    commit(SET_ATTRIBUTES, [])
    commit(SET_CHAINS, [])
  },

  async sendInvitation({ commit }, { account, client }) {
    const params = {
      account,
      brandId: client.brandId,
    }
    await postInvitationApi(params)
    commit
  },
  async sendAssociateGmbAccount({ commit }, { account, client }) {
    const params = {
      account,
      brandId: client.brandId,
    }
    await postAssociateGmbAccount(params)
    commit
  },
  async getAccountNotifications({ commit }, { clientId, account }) {
    const params = {
      clientId,
      account,
    }
    const { data } = await getAccountNotificationsApi(params)
    commit(SET_ADMINS, data.results.admins)
    commit(SET_NOTIFICATIONS, data.results.notifications)
  },
  async activateAccountNotifications({ commit }, { clientId, account }) {
    const params = {
      clientId,
      account,
    }
    const { data } = await postAccountNotificationsApi(params)
    commit(SET_NOTIFICATIONS, data.results.notifications)
  },
}

const getters = {
  hasMoreCategories: state => state.categories?.next?.nextPageToken?.length > 0,
  categoriesList: state => state.categories?.data || [],
  invitationsList: state => state.invitations?.results || [],
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
