import { getMediasTypeApi, postMediaApi, getMediasApi, patchMediaApi, putMediaApi } from '@/services/media.service'
import { buildCoreApiParams } from '@/utils/table.util'

const SET_MEDIAS_TYPE_AVAILABLE = 'SET_MEDIAS_TYPE_AVAILABLE'
const SET_CURRENT_MEDIAS = 'SET_CURRENT_MEDIAS'
const UPDATE_CURRENT_MEDIAS = 'UPDATE_CURRENT_MEDIAS'

const getDefaultState = () => {
  return {
    mediasTypeAvailable: [],
    currentMedias: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_MEDIAS_TYPE_AVAILABLE]: (state, mediasTypeAvailable) => {
    state.mediasTypeAvailable = mediasTypeAvailable
  },
  [SET_CURRENT_MEDIAS]: (state, currentMedias) => {
    state.currentMedias = currentMedias
  },
  [UPDATE_CURRENT_MEDIAS]: (state, { media, replace = false }) => {
    const mediaIndexToReplace = state.currentMedias.findIndex(media => media.id === media.id)
    if (replace) {
      state.currentMedias.splice(mediaIndexToReplace, 1, media)
    } else {
      state.currentMedias.splice(mediaIndexToReplace, 1)
    }
  },
}

const actions = {
  async getMediasType({ commit }, { query }) {
    const urlParams = buildCoreApiParams({ query })
    const brands = await getMediasTypeApi(urlParams)
    commit(SET_MEDIAS_TYPE_AVAILABLE, brands)
  },
  async postMedia({ state, commit }, body) {
    const media = await postMediaApi(body)
    commit(SET_CURRENT_MEDIAS, [...state.currentMedias, media])
  },
  async patchMedia({ commit }, { params, body }) {
    const urlParams = buildCoreApiParams({ params })
    const media = await patchMediaApi(urlParams, body)
    commit(UPDATE_CURRENT_MEDIAS, { media })
  },
  async putMediaApi({ commit }, { params, body }) {
    const urlParams = buildCoreApiParams({ params })
    const media = await putMediaApi(urlParams, body)
    commit(UPDATE_CURRENT_MEDIAS, { media, replace: true })
  },
  async getMedias({ commit }, { query }) {
    const urlParams = buildCoreApiParams({ query })
    const brands = await getMediasApi(urlParams)
    commit(SET_CURRENT_MEDIAS, brands)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
