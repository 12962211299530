import { sendGetRequest, sendPutRequest } from '@/services/api.service'
import { API_SA } from '@/config/api.config'

export const getSuperRgpdApi = urlParams => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.PC}${API_SA.SUPER_RGDPR}?${urlParams}`)
}

export const putSuperRgpdApi = data => {
  return sendPutRequest(`${API_SA.BASE_API_URL}${API_SA.PC}${API_SA.SUPER_RGDPR}`, data)
}

export const getGameReferences = search => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.PC}${API_SA.CAMPAIGN}?game_reference=${search}`)
}
