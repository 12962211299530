export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

export const encodeImageFileAsURL = (file, callback) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    callback(reader.result)
  }
  reader.readAsDataURL(file)
}

export const returnObjectByKeyAndValue = (array, scope) => {
  const objectWithKey = {}
  Object.keys(array).forEach(key => (objectWithKey[key] = array[key][scope]))
  return objectWithKey
}

export const stringToSlug = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]+/g, '')
    .replace(/[0-9]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const stringToSlugUnderscore = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]+/g, '')
    .replace(/[0-9]/g, '')
    .replace(/\s+/g, '_')
    .toLowerCase()
}

export const downloadText = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    let tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

export const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15)
}

export const copyToClipboard = string => {
  return navigator.clipboard.writeText(string)
}

export const cleanString = (string, removeThis) => {
  return string.split(removeThis)[1]
}

export const getShortLocal = locale => locale.substring(0, 2)
