import { getSuperRgpdApi, putSuperRgpdApi, getGameReferences } from '@/services/superRgpd.service'
import { tableOptions } from '@/utils/table.util'

const SET_SUPER_RGPDS = 'SET_SUPER_RGPDS'
const SET_SUPER_RGPD_PAGINATION = 'SET_SUPER_RGPD_PAGINATION'
const SET_GAME_REFERENCE = 'SET_GAME_REFERENCE'

const getDefaultState = () => {
  return {
    superRgpds: [],
    superRgpdsPagination: {},
    gameReferences: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_SUPER_RGPDS]: (state, clients) => {
    state.superRgpds = clients
  },
  [SET_SUPER_RGPD_PAGINATION]: (state, pagination) => {
    state.superRgpdsPagination = pagination
  },
  [SET_GAME_REFERENCE]: (state, gameReferences) => {
    state.gameReferences = gameReferences
  },
}

const actions = {
  async getSuperRgpds({ commit }, tableParams) {
    const urlParams = tableOptions(tableParams, 'superRgpdList')
    const { data, ...pagination } = await getSuperRgpdApi(urlParams)
    commit(SET_SUPER_RGPDS, data)
    commit(SET_SUPER_RGPD_PAGINATION, pagination)
    return Promise.resolve()
  },
  async createSuperRgpd({ commit }, body) {
    const { data } = await putSuperRgpdApi(body)
    commit(SET_SUPER_RGPDS, data)
  },
  async getGameReferences({ commit }, search) {
    const { data } = await getGameReferences(search)
    commit(SET_GAME_REFERENCE, data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
