const createFiltersQueryFromSearch = (filter, view) => {
  if (!(filter && filter.length > 0)) {
    return ''
  }
  const cleanSearch = filter.trim()
  switch (view) {
    case 'gmbCategories':
      return `displayName%3D${cleanSearch}`
    default:
      return ''
  }
}

const createCustomQuery = (custom, view) => {
  if (!(custom && custom.length > 0)) {
    return '&'
  }
  const cleanSearch = custom.trim()
  switch (view) {
    case 'superRgpdList':
      return `&game_reference=${cleanSearch}`
    default:
      return ''
  }
}

export const tableOptions = (tableParams, view) => {
  const options = tableParams || {}
  const urlParams = {
    size: options.itemsPerPage,
    page: options.page,
    name: options.search,
    filter: createFiltersQueryFromSearch(options.filter, view),
  }

  let paramsStringified = Object.entries(urlParams)
    .filter(([, urlParamValue]) => urlParamValue !== undefined && urlParamValue !== null && urlParamValue !== '')
    .map(([urlParamName, urlParamValue]) => `${urlParamName}=${urlParamValue}`)
    .join('&')
  if (options?.sortBy?.length > 0) {
    paramsStringified += `&sort=${options.sortBy[0]},${options.sortDesc[0] === true ? 'DESC' : 'ASC'}`
  }

  if (options.custom?.length > 0) {
    paramsStringified += createCustomQuery(options.custom, view)
  }
  return paramsStringified
}

// FOR THE NEW API IN SYMFONY

export const buildCoreApiParams = ({ params = {}, query = [], exists = [], options = {} }) => {
  const paramsBuild = Object.values(params)
    .map(param => {
      return `/${param}`
    })
    .join('')

  const existsBuild = exists
    .map((e, index) => {
      return `${index > 0 ? ',exists' : 'exists'}${e.key}=${e.value}`
    })
    .join('')

  const queryBuild = query
    .map((q, index) => {
      return `${index === 0 && !existsBuild ? '' : '&'}${q.key}=${q.value}`
    })
    .join('')

  const optionsBuild = Object.entries(options)
    .map((option, index) => {
      return `${index === 0 && !queryBuild ? '' : '&'}${option[0]}=${option[1]}`
    })
    .join('')

  return paramsBuild + existsBuild + queryBuild + optionsBuild
}
