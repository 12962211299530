import { getAuthApi } from '@/services/auth.service'
import { getLocalesApi } from '@/services/client.service'
import i18n from '@/i18n/i18n'
import vuetify from '@/plugins/vuetify'
import { getShortLocal } from '@/utils/utilities.util'

const SET_IS_LOGGED = 'SET_IS_LOGGED'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_LOCALES = 'SET_LOCALES'
const SET_LOADING = 'SET_LOADING'
const SET_UPDATING = 'SET_UPDATING'
const SET_ALERT = 'SET_ALERT'
const SET_DRAWER = 'SET_DRAWER'
const RESET_STATE = 'RESET_STATE'
const SET_MODULES = 'SET_MODULES'
const SET_MENUS = 'SET_MENUS'

const getDefaultState = () => {
  return {
    loading: true,
    updating: true,
    alert: {
      visible: false,
      color: '',
      text: '',
    },
    isLogged: false,
    currentUser: null,
    locales: null,
    drawer: null,
    modules: [],
    currentModule: 'superadmin',
    menus: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_IS_LOGGED]: (state, boolean) => {
    state.isLogged = boolean
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user
    i18n.locale = getShortLocal(user.locale)
    vuetify.framework.lang.current = i18n.locale
  },
  [SET_LOCALES]: (state, locales) => {
    state.locales = locales
  },
  [SET_LOADING]: (state, boolean) => {
    state.loading = boolean
  },
  [SET_UPDATING]: (state, boolean) => {
    state.updating = boolean
  },
  [SET_ALERT]: (state, payload) => {
    state.alert = {
      ...state.alert,
      ...payload,
    }
  },
  [SET_DRAWER]: (state, boolean) => {
    state.drawer = boolean
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
  [SET_MODULES]: (state, modules) => {
    state.modules = modules
  },
  [SET_MENUS]: (state, menus) => {
    state.menus = menus
  },
}

const actions = {
  async userLogout({ dispatch }) {
    dispatch('resetState')
  },
  async getAuthentication({ commit }) {
    const [{ user, modules }, locales] = await Promise.all([getAuthApi(), getLocalesApi()])
    commit(SET_CURRENT_USER, user)
    commit(SET_MODULES, modules)
    commit(SET_LOCALES, locales.results)
    commit(SET_IS_LOGGED, true)
  },
  setLoading({ commit }, bool) {
    commit(SET_LOADING, bool)
  },
  setUpdating({ commit }, bool) {
    commit(SET_UPDATING, bool)
  },
  setAlert({ commit }, payload) {
    commit(SET_ALERT, {
      visible: true,
      ...payload,
    })
  },
  setDrawer({ commit }, bool) {
    commit(SET_DRAWER, bool)
  },
  resetAlert({ commit }) {
    commit(SET_ALERT, {
      visible: false,
    })
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
