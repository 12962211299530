import { sendPostRequest, sendGetRequest } from '@/services/api.service'
import { API_PM } from '@/config/api.config'

export const postEVMFileApi = (clientId, file) => {
  return sendPostRequest(`${API_PM.BASE_API_URL}${API_PM.UPLOAD_FILE}?clientId=${clientId}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getExportFileApi = clientId => {
  return sendGetRequest(`${API_PM.BASE_API_URL}${API_PM.DOWNLOAD_FILE}?clientId=${clientId}`, {
    responseType: 'blob',
  })
}
