import Vue from 'vue'
import {
  getVerticalsApi,
  getTypesApi,
  postTypeApi,
  patchTypeApi,
  deleteTypeApi,
  getFeaturesApi,
  postFeatureApi,
  patchFeaturesPositionApi,
  patchFeatureApi,
  deleteFeatureApi,
  postVerticalApi,
  patchVerticalApi,
  deleteVerticalApi,
  patchMenuApi,
  postMenuApi,
  deleteMenuApi,
  getMenusCoreApi,
} from '@/services/setting.service'
import { buildCoreApiParams } from '@/utils/table.util'

const SET_VERTICALS = 'SET_VERTICALS'
const SET_TYPES = 'SET_TYPES'
const UPDATE_ARRAY = 'UPDATE_ARRAY'
const DELETE_ITEM_ARRAY = 'DELETE_ITEM_ARRAY'
const SET_FEATURES = 'SET_FEATURES'
const SET_MENUS = 'SET_MENUS'
const MOVE_FEATURES = 'MOVE_FEATURES'

const getDefaultState = () => {
  return {
    verticals: [],
    types: [],
    features: [],
    menus: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_VERTICALS]: (state, verticals) => {
    state.verticals = verticals
  },
  [SET_TYPES]: (state, types) => {
    state.types = types
  },
  [SET_MENUS]: (state, menus) => {
    state.menus = menus
  },
  [SET_FEATURES]: (state, features) => {
    state.features = features
  },
  [MOVE_FEATURES]: (state, { itemDragIndex, dropId }) => {
    const moveItem = state.features[itemDragIndex]
    const indexDrop = state.features.findIndex(feature => feature.identifier === dropId)
    Vue.delete(state.features, itemDragIndex)
    state.features.splice(indexDrop, 0, moveItem)
  },
  [UPDATE_ARRAY]: (state, { item, array }) => {
    const index = state[array].findIndex(old => old.id === item.id)
    Vue.set(state[array], index === -1 ? state[array].length : index, item)
  },
  [DELETE_ITEM_ARRAY]: (state, { item, array }) => {
    const index = state[array].findIndex(vertical => vertical.id === item.id)
    Vue.delete(state[array], index)
  },
}

const actions = {
  async getVerticals({ commit }) {
    const verticals = await getVerticalsApi()
    commit(SET_VERTICALS, verticals)
  },
  async postVertical({ commit }, vertical) {
    const verticalApi = await postVerticalApi(vertical)
    commit(UPDATE_ARRAY, { item: verticalApi, array: 'verticals' })
  },
  async patchVertical({ commit }, vertical) {
    const verticalApi = await patchVerticalApi(vertical)
    commit(UPDATE_ARRAY, { item: verticalApi, array: 'verticals' })
  },
  async deleteVertical({ commit }, vertical) {
    await deleteVerticalApi(vertical.slug)
    commit(DELETE_ITEM_ARRAY, { item: vertical, array: 'verticals' })
  },
  async getTypes({ commit }) {
    const types = await getTypesApi()
    commit(SET_TYPES, types)
  },
  async postType({ commit }, type) {
    const typeApi = await postTypeApi(type)
    commit(UPDATE_ARRAY, { item: typeApi, array: 'types' })
  },
  async patchType({ commit }, type) {
    const typeApi = await patchTypeApi(type)
    commit(UPDATE_ARRAY, { item: typeApi, array: 'types' })
  },
  async deleteType({ commit }, type) {
    await deleteTypeApi(type.slug)
    commit(DELETE_ITEM_ARRAY, { item: type, array: 'types' })
  },
  async getFeatures({ commit }, tableParams = { exists: [{ key: '[parentFeature]', value: false }] }) {
    const urlParams = buildCoreApiParams(tableParams)
    const features = await getFeaturesApi(urlParams)
    commit(SET_FEATURES, features)
  },
  async postFeature({ dispatch }, feature) {
    await postFeatureApi(feature)
    dispatch('getFeatures')
  },
  async patchFeaturesPosition(_, features) {
    const featuresWithPosition = features.map((item, index) => ({
      id: item.identifier,
      position: index + 1,
    }))
    await patchFeaturesPositionApi(featuresWithPosition)
  },
  async patchFeature({ dispatch }, { name, slug }) {
    await patchFeatureApi({ name, slug })
    dispatch('getFeatures')
  },
  async deleteFeature({ dispatch }, feature) {
    await deleteFeatureApi(feature.slug)
    dispatch('getFeatures')
  },
  async patchMenu(_, { menuId, menu }) {
    await patchMenuApi(menuId, menu)
  },
  async postMenu(_, menu) {
    await postMenuApi(menu)
  },
  async deleteMenu(_, menu) {
    await deleteMenuApi(menu)
  },
  async getMenusCore({ commit }) {
    const menus = await getMenusCoreApi()
    commit(SET_MENUS, menus)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
