<template>
  <v-app class="app">
    <Alert />
    <router-view />
  </v-app>
</template>

<script>
import Alert from '@/components/Alert.vue'

export default {
  name: 'App',
  components: {
    Alert,
  },
  created() {
    this.$i18n.locale = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2)
    if (this.$vuetify.theme.dark) document.documentElement.classList.add('tw-dark')
  },
}
</script>

<style lang="scss">
@tailwind base; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind components; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind utilities; /* stylelint-disable-line scss/at-rule-no-unknown */
</style>
