import {
  getBrandsApi,
  getBrandApi,
  patchBrandApi,
  getBrandCoreApi,
  getActivityApi,
  getGmbCategoryApi,
  postBrandApi,
  postBrandApiLegacy,
  getReviewsApi,
  getTouchpointsApi,
  putTouchpointsApi,
} from '@/services/brand.service'
import { tableOptions, buildCoreApiParams } from '@/utils/table.util'

const SET_BRANDS = 'SET_BRANDS'
const SET_REVIEWS = 'SET_REVIEWS'
const SET_REVIEWS_PAGINATION = 'SET_REVIEWS_PAGINATION'
const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND'
const SET_ACTIVITY = 'SET_ACTIVITY'
const SET_GMB_CATEGORY = 'SET_GMB_CATEGORY'
const SET_TOUCHPOINT = 'SET_TOUCHPOINT'

const getDefaultState = () => {
  return {
    brands: [],
    currentBrand: null,
    featuresList: [],
    activity: [],
    reviews: [],
    reviewsPagination: {},
    gmbCategory: [],
    touchpoints: {},
  }
}

const state = getDefaultState()

const mutations = {
  [SET_BRANDS]: (state, brands) => {
    state.brands = brands
  },
  [SET_REVIEWS]: (state, reviews) => {
    state.reviews = reviews
  },
  [SET_REVIEWS_PAGINATION]: (state, pagination) => {
    state.reviewsPagination = pagination
  },
  [SET_CURRENT_BRAND]: (state, brand) => {
    state.currentBrand = brand
  },
  [SET_ACTIVITY]: (state, activity) => {
    state.activity = activity
  },
  [SET_GMB_CATEGORY]: (state, gmbCategory) => {
    state.gmbCategory = gmbCategory
  },
  [SET_TOUCHPOINT]: (state, touchpoints) => {
    state.touchpoints = touchpoints
  },
}

const actions = {
  async getBrands({ commit }, { params, query, options }) {
    const urlParams = buildCoreApiParams({ params, query, options })
    const brands = await getBrandsApi(urlParams)
    commit(SET_BRANDS, brands)
  },
  async getReviews({ commit }, tableParams) {
    const urlParams = tableOptions(tableParams)
    const data = await getReviewsApi(urlParams)
    commit(SET_REVIEWS, data.data)
    const pagination = {
      page: data.page,
      size: data.size,
      total: data.total,
    }
    commit(SET_REVIEWS_PAGINATION, pagination)
  },
  async createBrand({ commit }, { brand }) {
    const brandApi = await postBrandApi(brand)
    commit(SET_CURRENT_BRAND, brandApi)
  },
  async getBrand({ commit }, { brandId }) {
    const { data } = await getBrandApi(brandId)
    commit(SET_CURRENT_BRAND, data)
  },
  async getBrandCore({ commit }, { params, query }) {
    const urlParams = buildCoreApiParams({ params, query })
    const brand = await getBrandCoreApi(urlParams)
    commit(SET_CURRENT_BRAND, brand)
  },
  async updateBrandLegacy({ commit }, { brand, brandId }) {
    const { data } = await postBrandApiLegacy(brandId, brand)
    commit(SET_CURRENT_BRAND, data)
  },
  async patchBrand({ commit }, { params, query, brand }) {
    const urlParams = buildCoreApiParams({ params, query })
    const brandApi = await patchBrandApi(urlParams, brand)
    commit(SET_CURRENT_BRAND, brandApi)
  },
  async getActivity({ commit }) {
    const { data } = await getActivityApi()
    commit(SET_ACTIVITY, data)
  },
  async getGmbCategory({ commit }) {
    const { data } = await getGmbCategoryApi()
    commit(SET_GMB_CATEGORY, data)
  },
  async getTouchpoints({ commit }, { brandId }) {
    const { data } = await getTouchpointsApi(brandId)
    commit(SET_TOUCHPOINT, data)
  },
  async updateTouchpoints(_, payload) {
    await putTouchpointsApi(payload)
    return Promise.resolve()
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
