import { sendGetRequest, sendPostRequest, sendPatchRequest, sendPutRequest } from '@/services/api.service'
import { GTR_CORE } from '@/config/api.config'

export const getMediasTypeApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MEDIA_TYPES}?${urlParams}`)
}

export const getMediasApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MEDIA}?${urlParams}`)
}

export const postMediaApi = body => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MEDIA}`, body)
}

export const patchMediaApi = (urlParams, body) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MEDIA}${urlParams}`, body)
}

export const putMediaApi = (urlParams, body) => {
  return sendPutRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MEDIA}${urlParams}`, body)
}
