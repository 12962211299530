import { sendPostRequest } from '@/services/api.service'
import { API_TOOLS } from '@/config/api.config'

export const postUploadMediaApi = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.UPLOAD_MEDIA}?clientId=${clientId}`, data)
}

export const postRemoveMediaApi = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.REMOVE_MEDIA}?clientId=${clientId}`, data)
}
