import { sendGetRequest, sendPostRequest, sendPatchRequest } from '@/services/api.service'
import { GTR_CORE, API_AUTH } from '@/config/api.config'

export const getUsersApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}?${urlParams}`)
}

export const getUserApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}${urlParams}`)
}

export const postUserApi = body => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}`, body)
}

export const patchUserApi = (urlParams, body) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}${urlParams}`, body)
}

export const getRolesApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.ROLES}`)
}

export const postSendInvitationApi = ({ email, token }) => {
  return sendPostRequest(`${API_AUTH.INVITE_USER}`, {
    username: email,
    'g-recaptcha-response': token,
    invitation: true,
  })
}
