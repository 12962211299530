import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/services/api.service'
import { API_SA } from '@/config/api.config'

export const getEndpointsApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}`)
}

export const getEndpointApi = ({ path, method }) => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}?path=${path}&method=${method}`)
}

export const postEndpointApi = ({ urlParams, body }) => {
  return sendPostRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}?${urlParams}`, body)
}

export const putEndpointApi = ({ urlParams, body }) => {
  return sendPutRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}?${urlParams}`, body)
}

export const getCacheApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}?get_cache`)
}

export const regenerateCacheApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.ENDPOINTS}?regen_cache`)
}
