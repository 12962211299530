import algoliasearch from 'algoliasearch'

// Internal

const ALGOLIA_API_ID = '0KNEMTBXX3'
const ALGOLIA_SEOCONF_API_KEY = '4b4bfe650f431478637de706c91d3271'
const ALGOLIA_PP_API_KEY = '7a46160ed01bb0af2c2af8d14b97f3c5'

// External

export const ALGOLIA_CLIENT = key => algoliasearch(ALGOLIA_API_ID, key)

export const SEO_INDEX_NAME = (indexName, locale) => `SEOCONF_${indexName}_${locale}`

export const PP_INDEX_NAME = (indexName, locale) => `PP_${indexName}_${locale}`

export const BASE_INDEX = (indexName, locale) => ALGOLIA_CLIENT(ALGOLIA_PP_API_KEY).initIndex(`${indexName}_${locale}`)

export const SEO_INDEX = (indexName, locale) =>
  ALGOLIA_CLIENT(ALGOLIA_SEOCONF_API_KEY).initIndex(SEO_INDEX_NAME(indexName, locale))

export const PP_INDEX = (indexName, locale) =>
  ALGOLIA_CLIENT(ALGOLIA_PP_API_KEY).initIndex(PP_INDEX_NAME(indexName, locale))

export const saveObjects = (index, object) => index.saveObject(object, { autoGenerateObjectIDIfNotExist: true })

export const CLEAR_CACHE = () => ALGOLIA_CLIENT(ALGOLIA_SEOCONF_API_KEY).clearCache()
