/* eslint-disable no-empty-pattern */
import { postUploadMediaApi, postRemoveMediaApi } from '@/services/tool.service'
import { formatedDate } from '@/utils/date.util'

const actions = {
  async uploadMedia({}, { target, file, mediaName, mediaId }) {
    let media = {
      target,
      name: `${mediaName}-${target}-${formatedDate('now', 'x')}`,
      file,
    }

    const { data } = await postUploadMediaApi(mediaId, media)
    return data.url
  },
  async removeMedia({}, url, mediaId) {
    await postRemoveMediaApi(mediaId, { url })
  },
}

export default {
  namespaced: true,
  actions,
}
