import { sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } from '@/services/api.service'
import { GTR_CORE } from '@/config/api.config'

export const getVerticalsApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.VERTICALS}?size=1000`)
}

export const postVerticalApi = vertical => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.VERTICALS}`, vertical)
}

export const patchVerticalApi = vertical => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.VERTICALS}/${vertical.slug}`, vertical)
}

export const deleteVerticalApi = slug => {
  return sendDeleteRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.VERTICALS}/${slug}`)
}

export const getTypesApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.TYPES}`)
}

export const postTypeApi = type => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.TYPES}`, type)
}

export const patchTypeApi = type => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.TYPES}/${type.slug}`, type)
}

export const deleteTypeApi = slug => {
  return sendDeleteRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.TYPES}/${slug}`)
}

export const getFeaturesApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES}?${urlParams}&order[position]=asc`)
}

export const postFeatureApi = feature => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES}`, feature)
}

export const patchFeaturesPositionApi = features => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES}/${GTR_CORE.BULK}`, features)
}

export const patchFeatureApi = feature => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES}/${feature.slug}`, feature)
}

export const deleteFeatureApi = id => {
  return sendDeleteRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES}/${id}`)
}

export const getMenusCoreApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MENUS}?exists[parentMenu]=false`)
}

export const postMenuApi = menu => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MENUS}`, menu)
}

export const deleteMenuApi = menu => {
  return sendDeleteRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MENUS}/${menu}`)
}

export const patchMenuApi = (menuId, menu) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MENUS}/${menuId}`, menu)
}
