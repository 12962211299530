import { SEO_INDEX, PP_INDEX, BASE_INDEX, saveObjects, CLEAR_CACHE } from '@/config/algolia.config'
import { buildFilters } from '@/utils/algolia.util'

const SET_BASE_INDEX = 'SET_BASE_INDEX'
const SET_SEO_INDEX = 'SET_SEO_INDEX'
const SET_PP_INDEX = 'SET_PP_INDEX'
const SET_CONFIG_CLIENT_SEO = 'SET_CONFIG_CLIENT_SEO'
const SET_FACETS_CATEGORIES = 'SET_FACETS_CATEGORIES'
const SET_CITIES_AVAILABLE = 'SET_CITIES_AVAILABLE'
const SET_LOCATIONS_CITY = 'SET_LOCATIONS_CITY'

const initConfigClientSeo = () => ({
  cityCount: 15,
  categories: [],
  categoryForSearch: null,
  websiteUrl: '',
  foUrl: '',
  contentSEO: {
    contentMetaTitle: [],
    contentMetaDescription: [],
    contentTitle: [],
    contentTitleProduct: [],
    contentTitleLocation: [],
    contentTitleSearch: [],
    contentDescription: [],
    contentDescription_location: [],
    contentDescription_search: [],
    contentDescription_about: [],
    contentFooterSeo: [],
  },
})

const getDefaultState = () => {
  return {
    seoIndex: null,
    baseIndex: null,
    ppIndex: null,
    configClientSeo: initConfigClientSeo(),
    facetsCategories: null,
    citiesAvailables: [],
    locationsInCity: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_BASE_INDEX]: (state, index) => {
    state.baseIndex = index
  },
  [SET_SEO_INDEX]: (state, algoliaIndex) => {
    state.seoIndex = algoliaIndex
  },
  [SET_PP_INDEX]: (state, PPAlgoliaIndex) => {
    state.ppIndex = PPAlgoliaIndex
  },
  [SET_CONFIG_CLIENT_SEO]: (state, configClientSeo) => {
    state.configClientSeo = configClientSeo
  },
  [SET_FACETS_CATEGORIES]: (state, facets) => {
    state.facetsCategories = facets
  },
  [SET_CITIES_AVAILABLE]: (state, cities) => {
    state.citiesAvailables = cities
  },
  [SET_LOCATIONS_CITY]: (state, locations) => {
    state.locationsInCity = locations
  },
}

const actions = {
  async initBaseIndex({ commit, dispatch }, { indexName, locale }) {
    const baseIndex = await BASE_INDEX(indexName, locale)
    commit('SET_BASE_INDEX', baseIndex)
    dispatch('getCityWithProductLocator', baseIndex)
  },
  async getLocationsInCity({ commit, state }, city) {
    const { hits } = await state.baseIndex.search('', {
      facets: ['product_locator'],
      filters: buildFilters({ 'city.name': city, product_locator: true }),
    })
    commit('SET_LOCATIONS_CITY', hits)
  },
  async getCityWithProductLocator({ commit, state }, index) {
    const { facetHits } = await index.searchForFacetValues('city.name', '', {
      maxFacetHits: state.configClientSeo.cityCount,
    })
    commit('SET_CITIES_AVAILABLE', facetHits)
  },
  async initPPIndex({ commit }, { indexName, locale }) {
    const PPAlgoliaIndex = await PP_INDEX(indexName, locale)
    commit(SET_PP_INDEX, PPAlgoliaIndex)
    const { facets } = await PPAlgoliaIndex.search('', {
      facets: ['hierarchicalCategories.lvl0', 'hierarchicalCategories.lvl1'],
      hitsPerPage: 0,
    })
    commit(SET_FACETS_CATEGORIES, facets)
  },
  async initSeoIndex({ commit }, { indexName, locale }) {
    await CLEAR_CACHE(indexName, locale)
    const seoAlgoliaIndex = await SEO_INDEX(indexName, locale)
    commit(SET_SEO_INDEX, seoAlgoliaIndex)
    const { hits } = await seoAlgoliaIndex.search()
    commit(SET_CONFIG_CLIENT_SEO, hits[0])
  },
  async indexSaveObjects(_, { index, object }) {
    await saveObjects(index, object)
  },
  async setConfigClientSeo({ commit }, { algoliaModel }) {
    commit(SET_CONFIG_CLIENT_SEO, algoliaModel)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
