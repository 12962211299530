import { sendGetRequest, sendPostRequest, sendPutRequest, sendPatchRequest } from '@/services/api.service'
import { API_SA, GTR_CORE } from '@/config/api.config'

export const getBrandApi = brandId => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.AR}${API_SA.BRAND}?id=${brandId}`)
}

export const getBrandsApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MARKETS}?${urlParams}`)
}

export const getBrandCoreApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MARKETS}${urlParams}`)
}

export const postBrandApi = brand => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MARKETS}`, brand)
}

export const postBrandApiLegacy = (brandId, data) => {
  return sendPostRequest(`${API_SA.BASE_API_URL}${API_SA.AR}${API_SA.BRAND}?id=${brandId}`, data)
}

export const patchBrandApi = (urlParams, brand) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MARKETS}${urlParams}`, brand)
}

export const getActivityApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.BOT}${API_SA.ACTIVITY}`)
}

export const getReviewsApi = urlParams => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.PC}${API_SA.CHECK_REVIEW_PAGES}?${urlParams}`)
}

export const getGmbCategoryApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.GOOGLE_CATEGORIES}`)
}

export const getTouchpointsApi = brandId => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.QR}${API_SA.BRAND_SETTINGS}?brandId=${brandId}`)
}

export const putTouchpointsApi = payload => {
  return sendPutRequest(`${API_SA.BASE_API_URL}${API_SA.QR}${API_SA.SETTING}`, payload)
}
