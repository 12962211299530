import { postEVMFileApi, getExportFileApi } from '@/services/presencemanagement.service'
import { downloadText } from '@/utils/utilities.util'
import { formatedDate } from '@/utils/date.util'

const initialState = () => {
  return {}
}

const actions = {
  async sendEVMFile({ rootState }, file) {
    await postEVMFileApi(rootState.brand.currentBrand.lpe_id, file)
  },

  async getExportFile({ rootState }) {
    const file = await getExportFileApi(rootState.brand.currentBrand.lpe_id)
    const fileName = `export_${formatedDate('now', 'DDMMYYYY')}`
    const fileExtension = 'txt'
    downloadText(file, `${fileName}.${fileExtension}`)
  },
}

export default {
  namespaced: true,
  state: initialState,
  actions,
}
