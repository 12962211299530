import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_GMB, API_SA } from '@/config/api.config'

export const getCategoriesApi = ({ regionCode, locale, filter, size, view = 'basic', nextPageToken }) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.CATEGORIES}?${filter}`, {
    params: {
      regionCode,
      locale,
      view,
      nextPageToken,
      size,
    },
  })
}

export const getChainsApi = query => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_GMB.GMB}${API_GMB.CHAINS}?query=${query}`)
}

export const getInvitationsApi = () => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_GMB.GMB}${API_GMB.INVITATIONS}`)
}

export const getAccountsApi = urlParams => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNTS}?${urlParams}`)
}

export const getAttributesApi = (countryCode, languageCode, categoryId) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.ATTRIBUTES}?regionCode=${countryCode}&locale=${languageCode}&gcid=${categoryId}`
  )
}

export const postInvitationApi = params => {
  return sendPostRequest(`${API_SA.BASE_API_URL}${API_GMB.GMB}${API_GMB.INVITATIONS}`, {}, { params })
}

export const postAssociateGmbAccount = params => {
  return sendPostRequest(`${API_SA.BASE_API_URL}${API_GMB.GMB}${API_GMB.ACCOUNT_ASSOCIATION}`, {}, { params })
}

export const getAccountNotificationsApi = params => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT}`, { params })
}

export const postAccountNotificationsApi = params => {
  return sendPostRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT_NOTIFICATION}`, {}, { params })
}
