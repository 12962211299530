import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import { URLS } from '@/config/urls.config'
import { getUserToken, isLogged, isAuthorized, isNavigationAccess } from '@/utils/auth.util'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Backoffice.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ClientsList',
        component: () => import('@/views/Clients/List.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'endpoints',
        component: () => import('@/views/Endpoint.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'list',
            name: 'EndpointList',
            component: () => import('@/views/Endpoints/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'super-rgpd',
        component: () => import('@/views/SuperRgpd.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'list',
            name: 'SuperRgpdList',
            component: () => import('@/views/SuperRgpd/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'create',
            name: 'SuperRgpdCreate',
            component: () => import('@/views/SuperRgpd/Create.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'user-sa',
        component: () => import('@/views/UserSA.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'list',
            name: 'UserSAList',
            component: () => import('@/views/UserSA/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'manage',
            name: 'UserSAManage',
            component: () => import('@/views/UserSA/Manage.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'manage/:id',
            name: 'UserSAEdit',
            component: () => import('@/views/UserSA/Manage.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'clients',
        component: () => import('@/views/Clients.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'dashboard/:id',
            name: 'ClientsDashboard',
            component: () => import('@/views/Clients/Dashboard.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'list',
            name: 'ClientsList',
            component: () => import('@/views/Clients/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'create',
            name: 'ClientCreate',
            component: () => import('@/views/Clients/Create.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'features/:id',
            name: 'ClientFeatures',
            component: () => import('@/views/Clients/Features.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/clients/:id/:currentTab(\\w+)?',
            name: 'Client',
            component: () => import('@/views/Clients/Id.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'brands',
        component: () => import('@/views/Brands.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'create/:id',
            name: 'BrandsCreate',
            component: () => import('@/views/Brands/Create.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'dashboard/:id',
            name: 'BrandsDashboard',
            component: () => import('@/views/Brands/Dashboard.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'list',
            name: 'BrandsList',
            component: () => import('@/views/Brands/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'reviews-list',
            name: 'ReviewsList',
            component: () => import('@/views/Brands/ReviewsList.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/brands/:id/:currentTab(\\w+)?',
            name: 'Brand',
            component: () => import('@/views/Brands/Id.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'creation/:currentTab(\\w+)?',
            name: 'settingsCreation',
            component: () => import('@/views/Settings/Creation.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'gmb/:currentTab(\\w+)?',
        name: 'GmbAdmin',
        component: () => import('@/views/Gmb/Admin.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  { path: '*', name: 'Error', component: () => import('@/views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const appState = store.state.backoffice

  isLogged(to, next, appState)

  if (isAuthorized(to, appState, getUserToken())) {
    return next()
  }

  if (getUserToken() !== undefined) {
    if (appState.currentUser && !isNavigationAccess(to.meta.roles, appState.currentUser)) {
      return next({ name: 'Error' })
    }
    return next()
  }

  await store.dispatch('backoffice/userLogout')
  return window.location.assign(`${URLS.SSO}/?app_version=sa&redirect=${encodeURIComponent(to.fullPath)}&sign_out=1`)
})

export default router
