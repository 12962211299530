import {
  getEndpointsApi,
  getEndpointApi,
  postEndpointApi,
  putEndpointApi,
  getCacheApi,
  regenerateCacheApi,
} from '@/services/endpoints.service'
import { buildCoreApiParams } from '@/utils/table.util'

const SET_ENDPOINTS = 'SET_ENDPOINTS'
const SET_CURRENT_ENDPOINT = 'SET_CURRENT_ENDPOINT'
const SET_JSON_CACHE = 'SET_JSON_CACHE'

const getDefaultState = () => {
  return {
    endpoints: [],
    currentEndpoint: [],
    jsonCache: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_ENDPOINTS]: (state, endpoints) => {
    state.endpoints = endpoints
  },
  [SET_CURRENT_ENDPOINT]: (state, endpoint) => {
    state.currentEndpoint = endpoint
  },
  [SET_JSON_CACHE]: (state, json) => {
    state.jsonCache = json
  },
}

const actions = {
  async getEndpoints({ commit }) {
    const { data } = await getEndpointsApi()
    commit(SET_ENDPOINTS, data)
  },
  async getEndpoint({ commit }, { path, method }) {
    const { data } = await getEndpointApi({ path, method })
    commit(SET_CURRENT_ENDPOINT, data)
  },
  async postEndpoint(_, { query, body }) {
    const urlParams = buildCoreApiParams({ query })
    await postEndpointApi({ urlParams, body })
  },
  async putEndpoint(_, { query, body }) {
    const urlParams = buildCoreApiParams({ query })
    await putEndpointApi({ urlParams, body })
  },
  async regenerateCache({ commit }) {
    const { data } = await regenerateCacheApi()
    commit(SET_JSON_CACHE, data)
  },
  async getCache({ commit }) {
    const { data } = await getCacheApi()
    commit(SET_JSON_CACHE, data)
  },
  setCache({ commit }, cache) {
    commit(SET_JSON_CACHE, cache)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
